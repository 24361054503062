<template>
  <div
    v-if="items?.length > 0"
    class="b-table__navbar"
  >
    <img
      src="/img/icons/table/icon_checked.svg"
      alt=""
      class="cursor"
      @click="clear_selected"
    />
    {{ items.length }} Товаров
    <div class="b-btns">
      <b-button
        v-if="!document?.fixed"
        variant="dark"
        @click="removeItem"
      >
        Удалить
      </b-button>

      <b-dropdown
        dropup
        no-caret
        class="navbar-dropdown"
        variant="dark"
      >
        <template #button-content> Печать </template>
        <b-dropdown-item @click="printPriceTags"> Печать ценников </b-dropdown-item>
        <b-dropdown-item @click="printLabels"> Печать этикеток </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import { PromoHeadModel } from '@/views/operational-processes/models/promo-head.model'
  export default {
    name: 'PromoNavbar',
    props: {
      items: {
        type: Array,
        default: () => []
      },
      clear_selected: {
        type: Function,
        default: () => {}
      },
      document: {
        type: PromoHeadModel,
        default: () => new PromoHeadModel()
      }
    },

    methods: {
      ...mapActions({
        setPrintActions: 'products/setPrintActions'
      }),
      removeItem() {
        this.$emit('remove_items', this.items)
      },
      async printPriceTags() {
        this.setPrintActions({
          print: true
        })
        this.$emit('print_price_tags', this.items?.length ? this.items : this.product_items)
      },

      printLabels() {
        this.setPrintActions({
          print_label: true
        })
        this.$emit('print_price_tags', this.items)
      }
    }
  }
</script>
