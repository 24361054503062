<template>
  <div class="spec">
    <promo-head
      :document="document"
      :loading="loadingFixed"
      @update-head="update_head"
      @set_loading="() => (first = true)"
      @fixed="fixed"
    />
    <div class="flex-card">
      <documents-table-filter
        class="p-3"
        placeholder="Поиск"
        :edit_fields="true"
      />
      <resizable-table
        :inside_card="false"
        table_name="spec-table"
        show_product_search
        :items="items"
        :busy="isBusy"
        :default_fields="fields"
        @scroll-up="scrollUp"
        @scroll-down="scrollDown"
      >
        <template #productSearch>
          <div
            class="d-flex"
            style="width: 200px"
          >
            <product-search
              v-if="!document.fixed"
              document_type="promotion"
              :document_head="document.id"
              :document="document"
              @select_product="createSpec"
            />
          </div>
        </template>
        <template #head_id>
          <e-checkbox
            position="center"
            :checked="allSelected"
            @click="selectAll()"
          />
        </template>
        <template #body_id="data">
          <div class="position-relative">
            <e-checkbox
              position="center"
              :checked="isSelectedItem(data.item.id)"
              @click="selectRow(data.item.id)"
            />
          </div>
        </template>
        <template #body_product="data">
          <product-spec-info :product="data.value" />
        </template>
        <template #body_percent="{ item }">
          <table-row-input
            :ref="`percent_${item.id}`"
            :value="item.percent"
            :input_id="`percent_${item.id}`"
            mask="###"
            type_input="percent_promotion"
            :fixed="document.fixed"
            @handle-input="(val) => setPercent(val, item, true)"
            @input="(val) => setPercent(val, item)"
          />
        </template>
        <template #body_retailPrice="{ item }">
          <table-row-input
            :ref="`price_${item.id}`"
            :value="item.retailPrice"
            :input_id="`retailPrice${item.id}`"
            :fixed="document.fixed"
            @handle-input="(val) => setRetailPrice(val, item, true)"
            @input="(val) => setRetailPrice(val, item)"
          />
        </template>
        <template #body_old_price="{ item }">
          <div>
            {{
              item.product?.promotion?.old_price
                ? item.product?.promotion?.old_price
                : item.product.retailPrice
                ? item.product.retailPrice
                : '-'
            }}
          </div>
        </template>
        <template #body_avg_pur="{ item }">
          <div>
            {{ isNumber(+item.avg_pur, 4) }}
          </div>
        </template>
        <template #body_rate_wo="{ item }">
          <div>
            {{ isNumber(+item.rate_wo, 4) }}
          </div>
        </template>
        <template #body_rate_w="{ item }">
          <div>
            {{ isNumber(+item.rate_w, 4) }}
          </div>
        </template>
        <template #body_growth="{ item }">
          {{ isNumber(+item.growth, 2) }}
        </template>
        <template #total></template>
      </resizable-table>
    </div>
    <modal-print-price-tags :products="printProducts" />
    <promo-navbar
      :items="selected"
      :document="document"
      :clear_selected="() => (this.selected = [])"
      @print_price_tags="printPriceTags"
      @remove_items="remove_items"
    />
  </div>
</template>

<script>
  import ModalPrintPriceTags from '@/views/core/modal/ModalPrintPriceTags.vue'
  import PromoNavbar from '@/views/operational-processes/components/promo/PromoNavbar'
  import ResizableTable from '@/components/ResizableTable.vue'
  import PromoHead from '@/views/operational-processes/components/promo/PromoHead.vue'
  import { PromoHeadModel } from '@/views/operational-processes/models/promo-head.model'
  import DocumentsTableFilter from '@/views/operational-processes/components/DocumentsTableFilter.vue'
  import ProductSearch from '@/views/operational-processes/components/ProductSearch.vue'
  import ProductSpecInfo from '@/views/operational-processes/components/ProductSpecInfo.vue'
  import TableRowInput from '@/views/operational-processes/components/TableRowInput.vue'
  import { mapActions, mapGetters } from 'vuex'
  import ECheckbox from '@/components/ECheckbox'
  import { ProductModel } from '@/models/product.model'

  export default {
    name: 'Specifications',
    components: {
      ECheckbox,
      TableRowInput,
      ProductSpecInfo,
      ProductSearch,
      DocumentsTableFilter,
      PromoHead,
      ResizableTable,
      PromoNavbar,
      ModalPrintPriceTags
    },
    apollo: {
      PromotionHead: {
        query: require('../../gql/PromotionHead.graphql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            input: {
              id: this.$route.params.id
            },
            pagination: this.pagination
          }
        },
        result({ data }) {
          if (!data) return
          this.document = new PromoHeadModel(data?.PromotionHead)
          this.setBreadcrumbs({ ...this.document, is_go_back: true })
          if (this.first) {
            this.items = this.document.specs.specs || []
            this.prev_cursor = data.PromotionHead.specs.prev_cursor
            this.next_cursor = data.PromotionHead.specs.next_cursor
          } else {
            if (this.up) {
              const initialHeight = document.querySelector('.table-docs').scrollHeight
              this.items.unshift(...this.document.specs.specs)
              this.prev_cursor = data.PromotionHead.specs.prev_cursor
              this.$nextTick(() => {
                document.querySelector('.table-docs').scrollTop =
                  document.querySelector('.table-docs').scrollHeight - initialHeight - 10
              })
            } else {
              this.items.push(...this.document.specs.specs)
              this.next_cursor = data.PromotionHead.specs.next_cursor
            }
          }
          this.isBusy = false
          this.first = false
          this.loading = false
        },
        error() {
          this.isBusy = false
        }
      }
    },
    data() {
      return {
        isBusy: true,
        loadingFixed: false,
        document: new PromoHeadModel(),
        allSelected: false,
        items: [],
        selected: [],
        up: false,
        first: true,
        loading: false,
        printProducts: [],
        pagination: {
          take: 20,
          next_cursor: '',
          prev_cursor: ''
        },
        fields: [
          { key: 'id', label: '', width: 40, checked: true },
          { key: 'order', label: '№', width: 40, checked: true },
          {
            key: 'product',
            label: 'Наименование товара',
            width: 250,
            checked: true
          },
          {
            key: 'percent',
            label: 'Скидка, %',
            width: 140,
            checked: true
          },
          {
            key: 'retailPrice',
            label: 'Цена со скидкой',
            width: 140,
            checked: true
          },
          {
            key: 'old_price',
            label: 'Цена без акции',
            width: 140,
            checked: true
          },
          {
            key: 'avg_pur',
            label: 'Ср. закуп. цена',
            width: 140,
            checked: true
          },
          {
            key: 'rate_wo',
            label: 'Скорость без скидки',
            width: 140,
            checked: true
          },
          {
            key: 'rate_w',
            label: 'Скорость со скидкой',
            width: 140,
            checked: true
          },
          {
            key: 'growth',
            label: 'Прирост, %',
            width: 140,
            checked: true
          }
        ],
        statusText: {
          DRAFT: { label: 'Черновик', style: 'color:#888888' },
          AWAITING: { label: 'Запланирована', style: 'color: #FFA800' },
          ACTIVE: { label: 'Активна', style: 'color: #00CB91' },
          FINISHED: { label: 'Завершена', style: 'color: #888888' },
          ERRORED: { label: 'Ошибка', style: 'color: #E53835' }
        },
        prev_cursor: '',
        next_cursor: ''
      }
    },
    computed: {
      ...mapGetters({ getCurrentBranch: 'settings/getCurrentBranch' }),
      selectedProducts() {
        return this.items
          .map((obj) => {
            if (this.selected.includes(obj.id)) {
              return obj.product
            }
          })
          .filter((obj) => {
            if (obj?.id) {
              return obj
            }
          })
      }
    },

    watch: {
      selected() {
        if (!this.selected.length && this.allSelected) this.allSelected = false
      }
    },

    methods: {
      ...mapActions({
        setIsDateStartError: 'promotion/setIsDateStartError',
        setIsDateEndError: 'promotion/setIsDateEndError',
        setIsNameError: 'promotion/setIsNameError',
        setBreadcrumbs: 'breadcrumbs/set_current'
      }),

      isNumber(num, fixed) {
        return !isNaN(num) && +num ? +num.toFixed(fixed) : '-'
      },

      selectAll() {
        this.allSelected = !this.allSelected
        if (this.allSelected) {
          this.selected = this.items.map((obj) => obj.id)
        } else {
          this.selected = []
        }
      },

      selectRow(id) {
        if (this.selected.includes(id)) {
          this.selected = this.selected.filter((obj) => obj !== id)
        } else {
          this.selected.push(id)
        }
      },
      isSelectedItem(id) {
        return this.selected.includes(id)
      },
      async printPriceTags(selectedEl) {
        let products = []
        for (const prod of selectedEl) {
          // const found = this.document.specs.specs.find((el) => el.id === prod)
          const found = this.items.find((el) => el.id === prod)
          if (found) {
            products.push(found.product.id)
          }
        }
        const { data } = await this.$apollo.query({
          query: require('../../gql/ReadPriceTagsFromDocument.graphql'),
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              document_head: this.document.id,
              branch: this.getCurrentBranch.id,
              product: products,
              operation: 'promotion',
              isPrintAll: this.allSelected
            }
          }
        })
        this.printProducts = data?.ReadPriceTagsFromDocument?.map((el) => {
          return new ProductModel({ ...el?.Product, retailPrice: el?.Product?.retailPrice })
        })
        if (this.printProducts?.length > 0) this.$bvModal.show('modal-print-tags')
      },
      scrollUp() {
        if (!this.prev_cursor || this.$apollo.queries.PromotionHead.loading) return
        this.up = true
        this.loading = true
        this.pagination.prev_cursor = this.prev_cursor
        this.pagination.next_cursor = ''
        this.$apollo.queries.PromotionHead.refetch()
      },
      async remove_items(items) {
        this.selected = []
        await this.$apollo.mutate({
          mutation: require('./gql/RemovePromotionProducts.gql'),
          variables: {
            input: {
              document_head: this.document.id,
              ids: items,
              isAllSelected: this.allSelected
            }
          }
        })
        this.first = true
        await this.$apollo.queries.PromotionHead.refetch()
      },
      scrollDown() {
        if (!this.next_cursor || this.$apollo.queries.PromotionHead.loading) return
        this.pagination.next_cursor = this.next_cursor
        this.up = false
        this.loading = true
        this.$apollo.queries.PromotionHead.refetch()
      },
      async setPercent(value, item) {
        item.setPercent(value)
        await this.$apollo.mutate({
          mutation: require('../../gql/CreatePromotionSpec.graphql'),
          variables: {
            input: { ...item.input, document_head: this.document.id }
          }
        })
      },
      async setRetailPrice(value, item) {
        if (item.product.retailPrice < value) {
          this.$noty.error('Цена со скидкой не может быть больше цены без акции')
          return
        }
        item.setRetailPrice(value)
        await this.$apollo.mutate({
          mutation: require('../../gql/CreatePromotionSpec.graphql'),
          variables: {
            input: { ...item.input, document_head: this.document.id }
          }
        })
      },
      async update_head(doc) {
        await this.$apollo.mutate({
          mutation: require('../../gql/UpdatePromotionHead.graphql'),
          variables: {
            input: doc.input
          }
        })
      },
      async createSpec(product) {
        if (product.specification) {
          let ref = this.document.type === 'PERCENT' ? 'percent_' : 'price_'
          if (!this.$refs[ref + product.specification.id]) {
            this.pagination.next_cursor = product.specification.cursor
            await this.$apollo.queries.PromotionHead.refetch()
          }
          setTimeout(() => {
            this.$refs[ref + product.specification.id].showInput()
          }, 100)

          document.querySelector('.table-docs').scrollTo({
            top: this.$refs[ref + product.specification.id].$el.offsetTop - 80
          })
          return
        }

        try {
          const { data } = await this.$apollo.mutate({
            mutation: require('../../gql/CreatePromotionSpec.graphql'),
            variables: {
              input: {
                product: product.id,
                document_head: this.document.id
              }
            }
          })
          this.pagination.next_cursor = data.CreatePromotionSpec.cursor
          this.pagination.prev_cursor = ''
          this.first = true
          await this.$apollo.queries.PromotionHead.refetch()
          // setTimeout(() => {
          //   document.querySelector('.table-docs').scrollTo({
          //     top: document.querySelector('.table-docs').scrollHeight,
          //     left: 0
          //   })
          // }, 1000)
        } catch (e) {
          console.error(e)
        }
      },
      async fixed(status) {
        try {
          if (!this.document.fixed) {
            if (!this.document.name) {
              this.$noty.error('Введите название акции')
              this.setIsNameError(false)
              return
            }
            this.setIsNameError(null)
            // if (new Date() > this.document.start_date) {
            //   this.setIsDateStartError(false)
            //   this.$noty.error('Введите корректную дату начала акции')
            //   return
            // }
            // this.setIsDateStartError(null)
            if (this.document.end_date <= this.document.start_date) {
              this.setIsDateEndError(false)
              this.$noty.error('Введите корректную конечную дату акции')
              return
            }
            this.setIsDateEndError(null)
          }
          this.loadingFixed = true
          await this.$apollo.mutate({
            mutation: require('../../gql/FixPromotionHead.graphql'),
            variables: {
              input: {
                fixed: status,
                id: this.document.id
              }
            }
          })
          this.$apollo.queries.PromotionHead.refetch()
        } catch (e) {
          this.loadingFixed = false
        } finally {
          this.loadingFixed = false
        }
      }
    },
    beforeMount() {
      this.setBreadcrumbs({ is_go_back: true })
    },
    beforeDestroy() {
      this.setBreadcrumbs({})
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep .search_product {
    padding: 0 !important;
  }

  .flex-card {
    border-radius: 3px;
    background: white;
    flex-grow: 1;
    min-height: 1px;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .spec {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
</style>
