import { BranchModel } from '@/models/branch.model'
import { OperationModel } from '@/models/operation.model'
import { UserModel } from '@/models/user.model'
import { StorageModel } from '@/models/storage.model'
import { EntityModel } from '@/models/entity.model'
import { InvoiceSpecPaginationModel } from '@/views/operational-processes/models/invoice-spec.model'

export class InvoiceHeadModel {
  constructor(request = {}) {
    this.id = request?.id
    this.branch = request?.branch ? new BranchModel(request?.branch) : null
    this.comment = request?.comment ?? ''
    this.storages = request?.storages ? request.storages.map((el) => new StorageModel(el)) : null
    this.date = request?.date ? new Date(request?.date) : new Date()
    this.fixed = request?.fixed
    this.number = request?.number
    this.operation = new OperationModel(request?.operation)
    this.owner = request?.owner ? new UserModel(request?.owner) : null
    this.sum = request?.sum
    this.new_sum = request?.new_sum
    this.sender_entity = new EntityModel(request.sender_entity)
    this.recipient_entity = new EntityModel(request.recipient_entity)
    this.specifications = new InvoiceSpecPaginationModel(request?.specifications)
  }

  setStorage = (val) => {
    this.storage = new StorageModel(val)
  }

  setComment = (val) => {
    this.comment = val
  }

  setSenderEntity = (val) => {
    this.sender_entity = new EntityModel({ id: val })
  }

  get input() {
    return {
      id: this.id,
      comment: this.comment,
      sender_entity: this.sender_entity?.id,
      recipient_entity: this.recipient_entity?.id
    }
  }

  setRecipientEntity = (val) => {
    this.recipient_entity = new EntityModel({ id: val })
  }
}
