var render = function render(){
  var _vm$filter_params;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "params-body"
  }, [_c('div', {
    staticClass: "card pb-0 pr-0 mb-0"
  }, [_c('b-form', {
    staticClass: "form pr-0"
  }, [_c('b-row', {
    staticClass: "w-100 align-items-end"
  }, [_c('b-col', {
    staticClass: "pr-0",
    attrs: {
      "xl": "2",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Название",
      "label-for": "name"
    }
  }, [[_c('b-form-input', {
    attrs: {
      "state": _vm.getIsNameError,
      "value": _vm.filter_params.name,
      "disabled": _vm.fixed
    },
    on: {
      "input": _vm.updateHead
    },
    model: {
      value: _vm.inv_head.name,
      callback: function ($$v) {
        _vm.$set(_vm.inv_head, "name", $$v);
      },
      expression: "inv_head.name"
    }
  })]], 2)], 1), _c('b-col', {
    staticClass: "pr-0 d-picker",
    staticStyle: {
      "max-width": "170px"
    },
    attrs: {
      "xl": "2",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Дата начала",
      "label-for": "work_period_begin"
    }
  }, [_vm.fixed ? [_vm._v(" " + _vm._s(_vm.convertDate(_vm.filter_params.start_date)) + " ")] : [_c('v-date-picker', {
    staticClass: "elm-calendar",
    class: {
      error: typeof _vm.getIsDateStartError === 'boolean' && !_vm.getIsDateStartError
    },
    attrs: {
      "id": "work_period",
      "disabled": _vm.fixed,
      "value": _vm.start_date,
      "min-date": new Date(),
      "mode": "datetime",
      "popover": {
        visibility: 'focus'
      },
      "visibility": 'click',
      "is24hr": ""
    },
    on: {
      "input": _vm.saveStartDate
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var inputValue = _ref.inputValue,
            inputEvents = _ref.inputEvents,
            togglePopover = _ref.togglePopover;
        return [_c('div', {
          staticStyle: {
            "position": "relative"
          }
        }, [_c('img', {
          staticClass: "calendar",
          attrs: {
            "src": "/img/icons/calendar.svg"
          },
          on: {
            "click": togglePopover
          }
        }), _c('input', _vm._g({
          directives: [{
            name: "mask",
            rawName: "v-mask",
            value: '##.##.#### ##:##',
            expression: "'##.##.#### ##:##'"
          }],
          staticClass: "px-2 py-1 border rounded focus:outline-none focus:border-blue-300",
          attrs: {
            "placeholder": "Выберите дату",
            "readonly": ""
          },
          domProps: {
            "value": inputValue
          },
          on: {
            "change": _vm.saveStartDate
          }
        }, inputEvents))])];
      }
    }])
  })]], 2)], 1), _c('b-col', {
    staticClass: "pr-0 d-picker",
    staticStyle: {
      "max-width": "170px"
    },
    attrs: {
      "xl": "2",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Дата конца",
      "label-for": "work_period"
    }
  }, [_vm.fixed ? [_vm._v(" " + _vm._s(_vm.convertDate(_vm.filter_params.end_date)) + " ")] : [_c('v-date-picker', {
    staticClass: "elm-calendar",
    class: {
      error: typeof _vm.getIsDateEndError === 'boolean' && !_vm.getIsDateEndError
    },
    attrs: {
      "id": "work_period",
      "disabled": _vm.fixed,
      "min-date": new Date(),
      "value": _vm.end_date,
      "popover": {
        visibility: 'focus'
      },
      "mode": "datetime",
      "is24hr": ""
    },
    on: {
      "input": _vm.saveEndDate
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var inputValue = _ref2.inputValue,
            inputEvents = _ref2.inputEvents,
            togglePopover = _ref2.togglePopover;
        return [_c('div', {
          staticStyle: {
            "position": "relative"
          }
        }, [_c('img', {
          staticClass: "calendar",
          attrs: {
            "src": "/img/icons/calendar.svg"
          },
          on: {
            "click": togglePopover
          }
        }), _c('input', _vm._g({
          directives: [{
            name: "mask",
            rawName: "v-mask",
            value: '##.##.#### ##:##',
            expression: "'##.##.#### ##:##'"
          }],
          staticClass: "px-2 py-1 border rounded focus:outline-none focus:border-blue-300",
          attrs: {
            "placeholder": "Выберите дату",
            "readonly": ""
          },
          domProps: {
            "value": inputValue
          },
          on: {
            "change": _vm.saveEndDate
          }
        }, inputEvents))])];
      }
    }])
  })]], 2)], 1)], 1), _c('b-row', {
    staticClass: "w-100"
  }, [_c('b-col', [_c('div', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.collapse-extraparams",
      modifiers: {
        "collapse-extraparams": true
      }
    }],
    staticClass: "btn-extra-param cursor mb-3"
  }, [_c('span', {
    staticClass: "isHide"
  }, [_vm._v("Показать")]), _c('span', {
    staticClass: "isShow"
  }, [_vm._v("Скрыть")]), _vm._v(" дополнительные параметры ")]), _c('b-collapse', {
    staticClass: "row mb-3",
    attrs: {
      "id": "collapse-extraparams"
    }
  }, [_c('b-col', {
    staticClass: "pr-0",
    attrs: {
      "col": "",
      "xl": "4",
      "lg": "4",
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Комментарий",
      "label-for": "comment"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "comment",
      "value": (_vm$filter_params = _vm.filter_params) === null || _vm$filter_params === void 0 ? void 0 : _vm$filter_params.comment,
      "rows": "3",
      "max-rows": "6",
      "is-keyup": true,
      "disabled": _vm.fixed
    },
    on: {
      "input": _vm.updateHead
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }