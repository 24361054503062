import { BranchModel } from '@/models/branch.model'
import { OperationModel } from '@/models/operation.model'
import { UserModel } from '@/models/user.model'
import { StorageModel } from '@/models/storage.model'
import { PromoSpecPaginationModel } from '@/views/operational-processes/models/promo-spec.model'

export class PromoHeadModel {
  constructor(request = {}) {
    this.id = request?.id
    this.name = request?.name
    this.branch = request?.branch ? new BranchModel(request?.branch) : null
    this.comment = request?.comment ?? ''
    this.storages = request?.storages ? request.storages.map((el) => new StorageModel(el)) : null
    this.start_date = request?.start_date ? new Date(request?.start_date) : new Date()
    this.end_date = request?.end_date ? new Date(request?.end_date) : new Date()
    this.fixed = request?.fixed
    this.status = request?.status
    this.type = request?.type
    this.number = request?.number
    this.operation = new OperationModel(request?.operation)
    this.owner = request?.owner ? new UserModel(request?.owner) : null
    this.specs = new PromoSpecPaginationModel(request?.specs)
  }

  setStorage = (val) => {
    this.storage = new StorageModel(val)
  }

  setComment = (val) => {
    this.comment = val
  }

  setStartDate = (val) => {
    this.start_date = new Date(val)
  }

  setEndDate = (val) => {
    this.end_date = new Date(val)
  }

  setType = (val) => {
    this.type = val
  }

  setName = (val) => {
    this.name = val
  }
  get input() {
    return {
      id: this.id,
      start_date: this.start_date,
      end_date: this.end_date,
      type: this.type,
      operation: 'promotion',
      name: this.name
    }
  }
}
