import { ProductModel } from '@/models/product.model'
import { InvoiceHeadModel } from '@/views/operational-processes/models/invoice-head.model'

export class PromoSpecModel {
  constructor(request = {}) {
    this.id = request?.id
    this.order = request?.order
    this.document_head = new InvoiceHeadModel(request?.document_head)
    this.product = new ProductModel(request?.product)
    this.percent = request?.percent || 0
    this.retailPrice = request?.retailPrice || Number((this.product.retailPrice * (1 - this.percent / 100)).toFixed(2))
    this.delta = request?.delta
    this.price = request?.price
    this.avg_pur = request?.avg_pur
    this.rate_wo = request?.rate_wo
    this.rate_w = request?.rate_w
    this.growth = request?.growth
  }

  setMarkup = (val) => {
    this.new_retailPrice = Number((this.price * (1 + val / 100)).toFixed(2))
  }

  setRetailPrice = (val) => {
    this.retailPrice = val
    this.percent = +Number((1 - val / this.product.retailPrice) * 100).toFixed(2)
  }

  setPercent = (val) => {
    this.percent = val
    this.retailPrice = +Number((this.product.retailPrice * (1 - val / 100)).toFixed(2))
  }

  setDelta = (val) => {
    this.delta = val
  }

  get delta_price() {
    return isFinite(+(this.new_retailPrice - this.retailPrice).toFixed(2))
      ? +(this.new_retailPrice - this.retailPrice).toFixed(2)
      : 0
  }

  get delta_percent() {
    return Number((((this.new_retailPrice - this.retailPrice) / this.retailPrice) * 100).toFixed(2)) || 0
  }

  get delta_sum() {
    return (+(this.new_retailPrice - this.retailPrice).toFixed(2) || 0) * this.delta || 0
  }

  get markup() {
    return Number((((this.new_retailPrice - this.price) / this.purchasePrice) * 100).toFixed(4)) || 0
  }

  get new_sum() {
    return +(this.new_retailPrice * this.delta).toFixed(2) || 0
  }

  get purchase_sum() {
    return +(this.purchasePrice * this.delta).toFixed(2) || 0
  }

  get retail_sum() {
    return +(this.retailPrice * this.delta).toFixed(2) || 0
  }

  get purchasePrice() {
    return +this.price?.toFixed(2) || 0
  }

  get input() {
    return {
      id: this.id ?? null,
      product: this.product.id,
      percent: +this.percent,
      retailPrice: +this.retailPrice
    }
  }

  get remainders() {
    let rems = 0
    this.product.remainders?.forEach((el) => (rems += el.value))
    return rems
  }
}

export class PromoSpecPaginationModel {
  constructor(request = {}) {
    this.specs = request?.specs?.map((el) => new PromoSpecModel(el))
    this.total = request?.total
    this.next_cursor = request?.next_cursor
    this.prev_cursor = request?.prev_cursor
  }
}
