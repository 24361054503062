var render = function render(){
  var _this = this;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "spec"
  }, [_c('promo-head', {
    attrs: {
      "document": _vm.document,
      "loading": _vm.loadingFixed
    },
    on: {
      "update-head": _vm.update_head,
      "set_loading": function () {
        return _vm.first = true;
      },
      "fixed": _vm.fixed
    }
  }), _c('div', {
    staticClass: "flex-card"
  }, [_c('documents-table-filter', {
    staticClass: "p-3",
    attrs: {
      "placeholder": "Поиск",
      "edit_fields": true
    }
  }), _c('resizable-table', {
    attrs: {
      "inside_card": false,
      "table_name": "spec-table",
      "show_product_search": "",
      "items": _vm.items,
      "busy": _vm.isBusy,
      "default_fields": _vm.fields
    },
    on: {
      "scroll-up": _vm.scrollUp,
      "scroll-down": _vm.scrollDown
    },
    scopedSlots: _vm._u([{
      key: "productSearch",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex",
          staticStyle: {
            "width": "200px"
          }
        }, [!_vm.document.fixed ? _c('product-search', {
          attrs: {
            "document_type": "promotion",
            "document_head": _vm.document.id,
            "document": _vm.document
          },
          on: {
            "select_product": _vm.createSpec
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "head_id",
      fn: function () {
        return [_c('e-checkbox', {
          attrs: {
            "position": "center",
            "checked": _vm.allSelected
          },
          on: {
            "click": function ($event) {
              return _vm.selectAll();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "body_id",
      fn: function (data) {
        return [_c('div', {
          staticClass: "position-relative"
        }, [_c('e-checkbox', {
          attrs: {
            "position": "center",
            "checked": _vm.isSelectedItem(data.item.id)
          },
          on: {
            "click": function ($event) {
              return _vm.selectRow(data.item.id);
            }
          }
        })], 1)];
      }
    }, {
      key: "body_product",
      fn: function (data) {
        return [_c('product-spec-info', {
          attrs: {
            "product": data.value
          }
        })];
      }
    }, {
      key: "body_percent",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('table-row-input', {
          ref: `percent_${item.id}`,
          attrs: {
            "value": item.percent,
            "input_id": `percent_${item.id}`,
            "mask": "###",
            "type_input": "percent_promotion",
            "fixed": _vm.document.fixed
          },
          on: {
            "handle-input": function (val) {
              return _vm.setPercent(val, item, true);
            },
            "input": function (val) {
              return _vm.setPercent(val, item);
            }
          }
        })];
      }
    }, {
      key: "body_retailPrice",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('table-row-input', {
          ref: `price_${item.id}`,
          attrs: {
            "value": item.retailPrice,
            "input_id": `retailPrice${item.id}`,
            "fixed": _vm.document.fixed
          },
          on: {
            "handle-input": function (val) {
              return _vm.setRetailPrice(val, item, true);
            },
            "input": function (val) {
              return _vm.setRetailPrice(val, item);
            }
          }
        })];
      }
    }, {
      key: "body_old_price",
      fn: function (_ref3) {
        var _item$product, _item$product$promoti, _item$product2, _item$product2$promot;

        var item = _ref3.item;
        return [_c('div', [_vm._v(" " + _vm._s((_item$product = item.product) !== null && _item$product !== void 0 && (_item$product$promoti = _item$product.promotion) !== null && _item$product$promoti !== void 0 && _item$product$promoti.old_price ? (_item$product2 = item.product) === null || _item$product2 === void 0 ? void 0 : (_item$product2$promot = _item$product2.promotion) === null || _item$product2$promot === void 0 ? void 0 : _item$product2$promot.old_price : item.product.retailPrice ? item.product.retailPrice : '-') + " ")])];
      }
    }, {
      key: "body_avg_pur",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('div', [_vm._v(" " + _vm._s(_vm.isNumber(+item.avg_pur, 4)) + " ")])];
      }
    }, {
      key: "body_rate_wo",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('div', [_vm._v(" " + _vm._s(_vm.isNumber(+item.rate_wo, 4)) + " ")])];
      }
    }, {
      key: "body_rate_w",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('div', [_vm._v(" " + _vm._s(_vm.isNumber(+item.rate_w, 4)) + " ")])];
      }
    }, {
      key: "body_growth",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(_vm.isNumber(+item.growth, 2)) + " ")];
      }
    }, {
      key: "total",
      fn: function () {
        return undefined;
      },
      proxy: true
    }])
  })], 1), _c('modal-print-price-tags', {
    attrs: {
      "products": _vm.printProducts
    }
  }), _c('promo-navbar', {
    attrs: {
      "items": _vm.selected,
      "document": _vm.document,
      "clear_selected": function () {
        return _this.selected = [];
      }
    },
    on: {
      "print_price_tags": _vm.printPriceTags,
      "remove_items": _vm.remove_items
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }