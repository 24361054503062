var render = function render(){
  var _vm$items, _vm$document;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return ((_vm$items = _vm.items) === null || _vm$items === void 0 ? void 0 : _vm$items.length) > 0 ? _c('div', {
    staticClass: "b-table__navbar"
  }, [_c('img', {
    staticClass: "cursor",
    attrs: {
      "src": "/img/icons/table/icon_checked.svg",
      "alt": ""
    },
    on: {
      "click": _vm.clear_selected
    }
  }), _vm._v(" " + _vm._s(_vm.items.length) + " Товаров "), _c('div', {
    staticClass: "b-btns"
  }, [!((_vm$document = _vm.document) !== null && _vm$document !== void 0 && _vm$document.fixed) ? _c('b-button', {
    attrs: {
      "variant": "dark"
    },
    on: {
      "click": _vm.removeItem
    }
  }, [_vm._v(" Удалить ")]) : _vm._e(), _c('b-dropdown', {
    staticClass: "navbar-dropdown",
    attrs: {
      "dropup": "",
      "no-caret": "",
      "variant": "dark"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_vm._v(" Печать ")];
      },
      proxy: true
    }], null, false, 1300152060)
  }, [_c('b-dropdown-item', {
    on: {
      "click": _vm.printPriceTags
    }
  }, [_vm._v(" Печать ценников ")]), _c('b-dropdown-item', {
    on: {
      "click": _vm.printLabels
    }
  }, [_vm._v(" Печать этикеток ")])], 1)], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }