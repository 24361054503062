<template>
  <div class="params-body">
    <div class="card pb-0 pr-0 mb-0">
      <b-form class="form pr-0">
        <b-row class="w-100 align-items-end">
          <b-col
            xl="2"
            lg="4"
            md="4"
            class="pr-0"
          >
            <b-form-group
              label="Название"
              label-for="name"
            >
              <template>
                <b-form-input
                  v-model="inv_head.name"
                  :state="getIsNameError"
                  :value="filter_params.name"
                  :disabled="fixed"
                  @input="updateHead"
                />
              </template>
            </b-form-group>
          </b-col>
          <b-col
            xl="2"
            lg="4"
            md="4"
            style="max-width: 170px"
            class="pr-0 d-picker"
          >
            <b-form-group
              label="Дата начала"
              label-for="work_period_begin"
            >
              <template v-if="fixed">
                {{ convertDate(filter_params.start_date) }}
              </template>
              <template v-else>
                <v-date-picker
                  id="work_period"
                  :disabled="fixed"
                  class="elm-calendar"
                  :class="{
                    error: typeof getIsDateStartError === 'boolean' && !getIsDateStartError
                  }"
                  :value="start_date"
                  :min-date="new Date()"
                  mode="datetime"
                  :popover="{ visibility: 'focus' }"
                  :visibility="'click'"
                  is24hr
                  @input="saveStartDate"
                >
                  <template #default="{ inputValue, inputEvents, togglePopover }">
                    <div style="position: relative">
                      <img
                        class="calendar"
                        src="/img/icons/calendar.svg"
                        @click="togglePopover"
                      />
                      <input
                        v-mask="'##.##.#### ##:##'"
                        class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                        :value="inputValue"
                        placeholder="Выберите дату"
                        readonly
                        v-on="inputEvents"
                        @change="saveStartDate"
                      />
                    </div>
                  </template>
                </v-date-picker>
              </template>
            </b-form-group>
          </b-col>
          <b-col
            xl="2"
            lg="4"
            md="4"
            style="max-width: 170px"
            class="pr-0 d-picker"
          >
            <b-form-group
              label="Дата конца"
              label-for="work_period"
            >
              <template v-if="fixed">
                {{ convertDate(filter_params.end_date) }}
              </template>
              <template v-else>
                <v-date-picker
                  id="work_period"
                  :disabled="fixed"
                  class="elm-calendar"
                  :class="{
                    error: typeof getIsDateEndError === 'boolean' && !getIsDateEndError
                  }"
                  :min-date="new Date()"
                  :value="end_date"
                  :popover="{ visibility: 'focus' }"
                  mode="datetime"
                  is24hr
                  @input="saveEndDate"
                >
                  <template #default="{ inputValue, inputEvents, togglePopover }">
                    <div style="position: relative">
                      <img
                        class="calendar"
                        src="/img/icons/calendar.svg"
                        @click="togglePopover"
                      />
                      <input
                        v-mask="'##.##.#### ##:##'"
                        class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                        :value="inputValue"
                        placeholder="Выберите дату"
                        readonly
                        @change="saveEndDate"
                        v-on="inputEvents"
                      />
                    </div>
                  </template>
                </v-date-picker>
              </template>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="w-100">
          <b-col>
            <div
              v-b-toggle.collapse-extraparams
              class="btn-extra-param cursor mb-3"
            >
              <span class="isHide">Показать</span>
              <span class="isShow">Скрыть</span>
              дополнительные параметры
            </div>
            <b-collapse
              id="collapse-extraparams"
              class="row mb-3"
            >
              <b-col
                col
                xl="4"
                lg="4"
                md="12"
                class="pr-0"
              >
                <b-form-group
                  label="Комментарий"
                  label-for="comment"
                >
                  <b-form-textarea
                    id="comment"
                    :value="filter_params?.comment"
                    rows="3"
                    max-rows="6"
                    :is-keyup="true"
                    :disabled="fixed"
                    @input="updateHead"
                  />
                </b-form-group>
              </b-col>
            </b-collapse>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>

<script>
  import formatDate from '@/utils/formatDate'
  import { PromoHeadModel } from '@/views/operational-processes/models/promo-head.model'
  import SelectPicker from '@/views/analytics/components/declaration/SelectPicker.vue'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'PromoFilters',
    components: { SelectPicker },

    props: {
      filter_params: {
        type: PromoHeadModel,
        default: () => new PromoHeadModel()
      },
      fixed: {
        type: Boolean,
        default: false
      },
      is_storage_error: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters({
        getIsDateStartError: 'promotion/getIsDateStartError',
        getIsDateEndError: 'promotion/getIsDateEndError',
        getIsNameError: 'promotion/getIsNameError'
      })
    },
    watch: {
      'filter_params.start_date'() {
        this.start_date = new Date(this.filter_params.start_date)
      },
      'filter_params.end_date'() {
        this.end_date = new Date(this.filter_params.end_date)
      },
      'filter_params.name'() {
        this.inv_head.name = this.filter_params.name
      },
      'inv_head.name'(newVal) {
        this.inv_head.name = newVal
        this.$emit('change', this.inv_head)
      }
    },
    data() {
      return {
        inv_head: {
          date: new Date(),
          storages: [],
          name: this.filter_params.name,
          comment: '',
          sender_entity: null,
          recipient_entity: null
        },
        supplier_list: [],
        start_date: '',
        end_date: '',
        timer: null
      }
    },
    methods: {
      ...mapActions({
        setIsDateStartError: 'promotion/setIsDateStartError',
        setIsDateEndError: 'promotion/setIsDateEndError',
        setIsNameError: 'promotion/setIsNameError'
      }),
      convertDate(date) {
        if (date) return formatDate(new Date(date), 'datetime')
      },
      updateHead() {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.$emit('change', this.inv_head)
        }, 300)
      },
      changeStorage(val) {
        this.inv_head.storage = val
        this.$emit('change', this.inv_head)
      },
      getDate(val) {
        const fullDate = val.split(' ')
        const date = fullDate[0].split('.')
        const time = fullDate[1].split(':')
        return new Date(+date[2], +date[1] - 1, +date[0], time[0], time[1])
      },

      saveEndDate(val) {
        this.inv_head.end_date = val
        this.setIsDateEndError(null)
        this.$emit('change', this.inv_head)
      },
      saveStartDate(val) {
        this.inv_head.start_date = val
        this.setIsDateStartError(null)
        this.$emit('change', this.inv_head)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .is-invalid {
    background-image: none;
  }
  .elm-calendar {
    &.error {
      input {
        border-color: #e53835 !important;
        background: #fffafa !important;
      }
    }
  }

  ::v-deep .vc-highlight {
    background-color: #00a3ff !important;
    border-radius: 2px !important;
  }
  ::v-deep .vc-container {
    font-family: 'Gilroy-Medium', sans-serif !important;
  }

  ::v-deep .vc-day-content {
    &:hover {
      border-radius: 2px !important;
      // background-color: rgba(204, 214, 224, 0.3) !important;
    }
    &:focus {
      border-radius: 2px !important;
      // background-color: rgba(204, 214, 224, 0.3) !important;
    }
  }
  ::v-deep .vc-date {
    .vc-month {
      color: #00a3ff !important;
    }
    .vc-day {
      color: #00a3ff !important;
    }
  }
  ::v-deep [data-v-f4e11af8] div .vc-time-icon {
    transform: translate(0px, 10px);
  }
  @media (max-width: 767px) {
    .d-picker {
      flex: 0 0 50%;
      max-width: 100% !important;
    }
  }
  .calendar {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 9px;
    transform: translate(0%, -50%);
  }
</style>
